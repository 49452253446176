import React from "react"
import Logo from "../images/brandLogos/roundLogo.png"
import SocialMedia from "./SocialMedia"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <div className="footer">
      <footer>
        <div className="logo">
          <img src={Logo} alt="logo" />
          <SocialMedia />
        </div>
        <div className="address">
          <h3>Address:</h3>
          <p>Sit Lo Saigon (Elk Grove):</p>
          <p>7405 Laguna Blvd. #170</p>
          <p>Elk Grove Ca, 95758</p>
          <p>916 . 647 . 9735</p>
          <br/>
          <p>Sit Lo Saigon (Davis):</p> 
          <p>424 G Street</p>
          <p>Davis, CA 95616</p>
          <p>530 . 231 . 5643</p>
        </div>
        <div className="hours">
          <h3>Hours (Elk Grove):</h3>
          <p>10am - 8:30pm</p>
          <p>Both indoor and patio seating open</p>
          <br />
          <h3>Hours (Davis):</h3>
          <p>11am - 8:30pm</p>
        </div>
      </footer>
      <hr />
      <h3 className="bottom-footer">
        @2022 Sit Lo Saigon, brought to you by the creators of{" "}
        <a href="https://www.saigonalley.com/" target="_blank">
          Saigon Alley Kitchen & Bar
        </a>
      </h3>
    </div>
  )
}

export default Footer
