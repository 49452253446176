import React from "react"
import '../styles/styles.scss';
import MobileNav from './MobileNav'
import Footer from "./footer"
import * as layoutStyles from "./layout.module.scss"

const Layout = props => {
  return (
    <div className={layoutStyles.container}>
      <MobileNav />
      <div className={layoutStyles.content}>
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
