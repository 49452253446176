import React from 'react';
import { SocialIcon } from 'react-social-icons';

const SocialMedia = () => {
	return (
		<div className="social-media">
			<SocialIcon fgColor="#1E847C" bgColor="transparent" className="social-media-icon" url="https://www.facebook.com/sitlosaigon/" />
			<SocialIcon fgColor="#1E847C" bgColor="transparent" className="social-media-icon" url="https://www.yelp.com/biz/sit-lo-saigon-elk-grove" />
			<SocialIcon fgColor="#1E847C" bgColor="transparent" className="social-media-icon" url="https://www.instagram.com/sitlosaigon/" />
		</div>
	);
};

export default SocialMedia;