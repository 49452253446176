import React, { useState } from "react"
import { stack as Menu } from "react-burger-menu"
import { Link } from "gatsby"

const links = [
	{ path: "/", name: "Home" },
	{ path: "/menu", name: "Menu" },
	{ path: "/gallery", name: "Gallery" },
	{ path: "https://www.toasttab.com/sitlosaigon", name: "Order Online (Elk Grove)" },
	{ path: "https://www.toasttab.com/sit-lo-saigon-davis-424-g-street/v3/?mode=fulfillment", name: "Order Online (Davis)" }
]

const socialLinks = [
  { path: "https://www.facebook.com/sitlosaigon/", name: "Facebook" },
  { path: "https://www.yelp.com/biz/sit-lo-saigon-elk-grove", name: "Yelp" },
  { path: "https://www.instagram.com/sitlosaigon/", name: "Instagram" },
]

const MobileNav = () => {
  return (
    <div className="mobile-navigation">
      <Menu>
        {links.map((link, index) => (
          <Link
            activeStyle={{ color: "#4A4449" }}
            className="menu-item"
            to={link.path}
            key={index}
          >
            {link.name}
          </Link>
        ))}
        <p>Connect with us</p>
        {socialLinks.map((link, index) => (
          <Link
            target="_blank"
            activeStyle={{ color: "#4A4449" }}
            className="menu-item"
            to={link.path}
            target="_blank"
            key={index}
          >
            {link.name}
          </Link>
        ))}
      </Menu>
    </div>
  )
}

export default MobileNav
